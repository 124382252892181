import React, { useState } from 'react';
import { FilePdfOutlined, FileTextOutlined, FileTextTwoTone } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

const menuStyle: React.CSSProperties = {
  justifyContent: 'end',
}

const items: MenuProps['items'] = [
  {
    label: 'Документи',
    key: 'docs',
    icon: <FilePdfOutlined />,
    children: [
      {
        label: (
          <a href="https://dashkovetske-dzherelo.com.ua/docs/regulations" target="_blank" rel="noopener noreferrer">
            Статут
          </a>
        ),
        key: 'regulations',
        icon: <FileTextTwoTone />
      },
      {
        label: (
          <a href="https://dashkovetske-dzherelo.com.ua/docs/contract" target="_blank" rel="noopener noreferrer">
            Договір про надання послуг
          </a>
        ),
        key: 'contract',
        icon: <FileTextTwoTone />
      },
      {
        label: (
          <a href="https://dashkovetske-dzherelo.com.ua/docs/water-supply-tariff-calculation-2023" target="_blank" rel="noopener noreferrer">
            Калькуляція тарифу
          </a>
        ),
        key: 'water-supply-tariff-calculation-2023',
      },
      {
        label: 'Заяви',
        key: 'zayavy',
        children: [
          {
            label: (
              <a href="https://dashkovetske-dzherelo.com.ua/docs/request-to-join" target="_blank" rel="noopener noreferrer">
                Заява про приєднання
              </a>
            ),
            key: 'request-to-join',
          },
        ]
      },
    ],
  },
  {
    label: (
      <a href="https://dashkovetske-dzherelo.com.ua/about" target="_blank" rel="noopener noreferrer">
        Про кооператив
      </a>
    ),
    key: 'about',
  },

];

const Navigation: React.FC = () => {
  const [current, setCurrent] = useState('');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} style={menuStyle} />;
};

export default Navigation;