import React from 'react';
import env from "react-dotenv";
import { Layout, Row, Col, Button } from 'antd';
import Auth from './components/pages/registration';
import Navigation from './components/header/navigation';

import logo from './logo.svg';

const { Header, Footer, Content } = Layout;

const logoStyle: React.CSSProperties = {
  height: '10vmin',
}

const headerStyle: React.CSSProperties = {
  backgroundColor: 'Background',
  position: 'sticky',
  top: 0,
  zIndex: 10000,
  alignItems: 'center',
  margin: '10px 0',
  justifyContent: 'space-between',
  height: '10vmin'
}

const contentStyle: React.CSSProperties = {
  textAlign: 'center'
};

const rowStyle: React.CSSProperties = {
  justifyContent: 'space-between',
};

const lastColStyle: React.CSSProperties = {
  textAlign: 'right'
};

const App: React.FC = () => {

  return (
    <Layout className="layout">
      <Header style={headerStyle}>
        <Row style={rowStyle}>
          <Col span={6}>
            <a href={env.REACT_APP_BASE_DOMAIN}>
              <img style={logoStyle} src={logo} alt='логотип' />
            </a>
          </Col>
          <Col span={14}>
            <Navigation></Navigation>
          </Col>
          <Col span={4} style={lastColStyle}>
            <Button type='primary' shape='round' href={env.REACT_APP_MY_DZHERLO}>Моє джерело</Button>
          </Col>
        </Row>
      </Header>
      <Content style={contentStyle}>
        <Auth/>
      </Content>
      <Footer style={{ textAlign: 'left' }}>©2022-{new Date().getFullYear()} кооператив Дашковецьке джерело</Footer>
    </Layout>
  );
}

export default App;