import http from '../http-common';
import IAuth from '../types/Auth';

const signUp = (data: IAuth) => {
   return http.post<IAuth>('/sign-up', data);
};

const login = (username: string, password: string) => {
return http.post<any>('/login', {username: username, password: password});
};

const AuthService = {
   signUp,
   login
};

export default AuthService;
