import React, { useState, useEffect } from 'react';
import { Form, Card, Input, Radio, Button, Row, Col, Select } from 'antd';

import RegistrationForm from '../forms/registration.form';

const Registration: React.FC = () => {
  const rowStyle: React.CSSProperties = {
    justifyContent: 'center',
    textAlign: 'left',
    margin: '2rem 0 0 0'
  }

  return (
    <Row style={rowStyle}>
      <Col span={18} lg={10}>
        <Card title='Реєстрація'>
          <RegistrationForm/>
        </Card>
      </Col>
    </Row>
  );
};

export default Registration;
