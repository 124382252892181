import bcrypt from 'bcryptjs';

const HashEncryptor = async (str: string) => {
    const saltRounds = 10;

    try {
        const salt = await bcrypt.genSalt(saltRounds);
        const hashedPassword = await bcrypt.hash(str, salt);
        return hashedPassword;
    } catch (error) {
        console.error('Error during encrypt:', error);
    }
};

const HashEncryptors = async (arr: string[]) => {
    const saltRounds = 10;
    let hashedArr: string[] = [];

    try {
        const salt = await bcrypt.genSalt(saltRounds);
        for (const value of arr) {
            const hashedPassword = await bcrypt.hash(value, salt);
            hashedArr.push(hashedPassword);
        }
        return hashedArr;
    } catch (error) {
        console.error('Error during encryption:', error);
        return [];
    }
};


export { HashEncryptor, HashEncryptors };
