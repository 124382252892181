import React, { useState, useEffect, useRef } from 'react';
import { Form, Card, Input, Radio, Button, RadioChangeEvent, RadioGroupProps, Space, message, Spin, InputRef } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useCookies } from 'react-cookie';
import MediaQuery, { useMediaQuery } from 'react-responsive'
import MaskedInput from 'antd-mask-input';
import { HashEncryptor, HashEncryptors } from '../../utils/encrypt.util';
import IAuth from '../../types/Auth';
import { RegistrationMethodType } from '../../types/User';
import AuthDataService from '../../services/AuthService';
import { responsiveArray } from 'antd/es/_util/responsiveObserver';

type LayoutType = Parameters<typeof Form>[0]['layout'];
type DirectionType = Parameters<typeof Space>[0]['direction'];

//regexp
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const phoneRegex = /^\+380\d{9}$/;
const telegramRegex = /^\d{1,}$/;
const snRegex = /^\d{1}[A-Z]{2}\d{11}$|^00\d{6}$|^22\d{5}$|^12\d{8}$|^21\d{7}$|^01\d{5}$|^0600\d{4}$|^20\d{7}$|^23\d{5}$|^07\d{4}$|^02\d{4}$|^24\d{5}$|^15\d{4}$|^00\d{5}$|^0200\d{6}$|^16\d{6}$|^06\d{4}$|^00\d{8}$/;
const userLastNameRegex = /^[А-Яа-яЇїІіЄєҐґ']{1,25}$/;
const passwordRegex = /^(?=.*[A-ZА-ЯЇЄІҐ]).{6,}$/;

const RegistrationForm: React.FC = () => {
  //media query
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isLargeScreen = useMediaQuery({ minWidth: 1024 });

  //form
  const [form] = Form.useForm();
  const [formLayout] = useState<LayoutType>('vertical');

  const initialValues = {
    registrationMethod: RegistrationMethodType.none,
    email: '',
    telegram: '',
    phone: '',
    sn: '2297460',
    lastName: 'Павлович',
    password: '11111A',
    confirmPassword: '11111A'
  };

  //init state
  const [auth, setAuth] = useState<IAuth>(initialValues);
  const [isHasEmailValid, setHasEmail] = useState<boolean>(true);
  const [isHasPhoneValid, setHasPhone] = useState<boolean>(true);
  const [isHasTelegramValid, setHasTelegram] = useState<boolean>(true);
  const [isHasMeterSerialNumberValid, setHasMeterSerialNumber] = useState<boolean>(true);
  const [isHasLastNameValid, setHasLastName] = useState<boolean>(true);
  const [isHasPasswordValid, setHasPassword] = useState<boolean>(true);
  const [isHasConfirmPasswordValid, setHasConfirmPassword] = useState<boolean>(true);

  //states
  const [loading, setLoading] = useState(false);
  const [directionType, setDirectionType] = useState<DirectionType>('horizontal');
  const [registrationMethod, setRegistrationMethod] = useState<RegistrationMethodType>(RegistrationMethodType.email);

  // valid states
  const [isEmailValid, setEmailValid] = useState<boolean>(false);
  const [isPhoneValid, setPhoneValid] = useState<boolean>(false);
  const [isTelegramValid, setTelegramValid] = useState<boolean>(false);
  const [isMeterSerialNumberValid, setMeterSerialNumberValid] = useState<boolean>(false);
  const [isLastNameValid, setLastNameValid] = useState<boolean>(false);
  const [isPasswordValid, setPasswordValid] = useState<boolean>(false);
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState<boolean>(false);

  //error states
  const [emailError, setEmailError] = useState({ enable: false, helpText: '' });
  const [phoneError, setPhoneError] = useState({ enable: false, helpText: '' });
  const [telegramError, setTelegramError] = useState({ enable: false, helpText: '' });
  const [snError, setSNError] = useState({ enable: false, helpText: '' });
  const [lastNameError, setLastNameError] = useState({ enable: false, helpText: '' });
  const [passwordError, setPasswordError] = useState({ enable: false, helpText: '' });
  const [confirmPasswordError, setConfirmPasswordError] = useState({ enable: false, helpText: '' });

  //error ref
  const emailErrorRef = useRef(emailError);
  const phoneErrorRef = useRef(phoneError);
  const telegramErrorRef = useRef(telegramError);
  const snErrorRef = useRef(snError);
  const lastNameErrorRef = useRef(lastNameError);
  const passwordErrorRef = useRef(passwordError);
  const confirmPasswordErrorRef = useRef(confirmPasswordError);

  //input ref
  const emailInput = useRef<InputRef>(null);
  const phoneInput = useRef<InputRef>(null);
  const telegramInput = useRef<InputRef>(null);
  const snInput = useRef<InputRef>(null);
  const lastNameInput = useRef<InputRef>(null);
  const passwordInput = useRef<InputRef>(null);
  const confirmPasswordInput = useRef<InputRef>(null);


  //cookies
  const [cookies, setCookie] = useCookies(['registration_source', 'registration_source_id']);

  //effects
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.source && params.id) {
      setCookie('registration_source', params.source, { path: '/' });
      setCookie('registration_source_id', params.id, { path: '/' });
    }
  }, []);

  useEffect(() => {
    if (cookies.registration_source && cookies.registration_source_id) {
      if (cookies.registration_source === RegistrationMethodType.telegram) {
        const value = { ...auth, registrationMethod: RegistrationMethodType.telegram, telegram: cookies.registration_source_id };
        form.setFieldsValue({ telegram: cookies.registration_source_id, registrationMethod: cookies.registration_source });
        setRegistrationMethod(cookies.registration_source as RegistrationMethodType);
        setHasTelegram(false);
        setTelegramValid(true);
        setTelegramError({ enable: false, helpText: '' });
        setAuth(value);
      }
    }
  }, [setCookie]);

  useEffect(() => {
    emailErrorRef.current = emailError;
    if (emailError.enable) {
      form.validateFields();
    }
    phoneErrorRef.current = phoneError;
    if (phoneError.enable) {
      form.validateFields();
    }
    telegramErrorRef.current = telegramError;
    if (telegramError.enable) {
      form.validateFields();
    }
    snErrorRef.current = snError;
    if (snError.enable) {
      form.validateFields();
    }
    lastNameErrorRef.current = lastNameError;
    if (lastNameError.enable) {
      form.validateFields();
    }
    passwordErrorRef.current = passwordError;
    if (passwordError.enable) {
      form.validateFields();
    }
    confirmPasswordErrorRef.current = confirmPasswordError;
    if (confirmPasswordError.enable) {
      form.validateFields();
    }
  }, [emailError, phoneError, telegramError, snError, lastNameError, passwordError, confirmPasswordError]);


  //handlers
  const onFormChange = () => {
    if (emailError.enable) {
      setEmailValid(false);
      setEmailError({ enable: false, helpText: '' });
    }
    if (phoneError.enable) {
      setPhoneValid(false);
      setPhoneError({ enable: false, helpText: '' });
    }
    if (telegramError.enable) {
      setTelegramValid(false);
      setTelegramError({ enable: false, helpText: '' });
    }
    if (snError.enable) {
      setMeterSerialNumberValid(false);
      setSNError({ enable: false, helpText: '' });
    }
    if (lastNameError.enable) {
      setLastNameValid(false);
      setLastNameError({ enable: false, helpText: '' });
    }
    if (passwordError.enable) {
      setPasswordValid(false);
      setPasswordError({ enable: false, helpText: '' });
    }
    if (confirmPasswordError.enable) {
      setConfirmPasswordValid(false);
      setConfirmPasswordError({ enable: false, helpText: '' });
    }
  }

  const handleRegistrationTypeChange = (e: RadioChangeEvent) => {
    const value = e.target.value as RegistrationMethodType;
    setRegistrationMethod(value);

    if (value === RegistrationMethodType.email) {
      form.resetFields(['phone', 'telegram']);
      setAuth({ ...auth, registrationMethod: value, phone: '', telegram: '' });
    } else if (value === RegistrationMethodType.phone) {
      form.resetFields(['email', 'telegram']);
      setAuth({ ...auth, registrationMethod: value, telegram: '', email: '' });
    }
    else if (value === RegistrationMethodType.telegram) {
      form.setFieldsValue({ telegram: cookies.registration_source_id });
      form.resetFields(['phone', 'email']);
      setHasTelegram(false);
      const isValid = telegramRegex.test(cookies.registration_source_id);
      setTelegramValid(isValid);
      setTelegramError( {enable: !isValid, helpText:''});
      setAuth({ ...auth, registrationMethod: value, phone: '', email: '', telegram: cookies.registration_source_id });
    } else {
      form.resetFields(['phone', 'email', 'telegram']);
      setAuth({ ...auth, registrationMethod: value, phone: '', email: '', telegram: '' });
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasEmail(false);
    const { name, value } = e.target;
    const isValid = emailRegex.test(value);
    setEmailValid(isValid);
    setAuth({ ...auth, ['email']: value })
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasPhone(false);
    const { name, value } = e.target;
    const isValid = phoneRegex.test(value);
    setPhoneValid(isValid);
    setAuth({ ...auth, ['phone']: value });
  };

  const handleTelegramChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasTelegram(false);
    const { name, value } = e.target;
    const isValid = telegramRegex.test(value);
    setTelegramValid(isValid);
    setAuth({ ...auth, ['telegram']: value });
  };

  const handleMeterSerialNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasMeterSerialNumber(false);
    const { name, value } = e.target;
    const isValid = snRegex.test(value);
    setMeterSerialNumberValid(isValid);
    setAuth({ ...auth, sn: value });
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasLastName(false);
    const { name, value } = e.target;
    const isValid = userLastNameRegex.test(value);
    setLastNameValid(isValid);
    setAuth({ ...auth, lastName: value })
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasPassword(false);
    const { name, value } = e.target;
    const isValid = passwordRegex.test(value);
    setPasswordValid(isValid);
    setAuth({ ...auth, password: value });
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasConfirmPassword(false);
    const { name, value } = e.target;
    const isValid = passwordRegex.test(value);
    setConfirmPasswordValid(isValid);
    setAuth({ ...auth, confirmPassword: value })
  };

  const onFinish = (values: any) => {
    setLoading(true);

    HashEncryptors([values.password, values.confirmPassword]).then(hashArr => {
      const [password, confirmPassword] = hashArr;
      const encryptData = {
        password: password,
        confirmPassword: confirmPassword,
      };

      const data = { ...auth, ...encryptData } as IAuth;
      AuthDataService.signUp(data).then((response: any) => {
        console.log(response);
        setLoading(false);
      }).catch((error) => {
        const separator = '\n';
        const validatorMessages = error.response.data && error.response.data.validatorMessages;
        const messages = JSON.parse(validatorMessages);

        // If the API fails with the below error, then update the state variable.
        if (messages.email && messages.email.length) {
          setHasEmail(false);
          setEmailValid(false);
          setEmailError({
            enable: true,
            helpText: messages.email.join(separator)
          });
        }
        if (messages.phone && messages.phone.length) {
          setHasPhone(false);
          setPhoneValid(false);
          setPhoneError({
            enable: true,
            helpText: messages.phone.join(separator)
          });
        }
        if (messages.telegram && messages.telegram.length) {
          setHasTelegram(false);
          setTelegramValid(false);
          setTelegramError({
            enable: true,
            helpText: messages.telegram.join(separator)
          });
        }
        if (messages.sn && messages.sn.length) {
          setHasMeterSerialNumber(false);
          setMeterSerialNumberValid(false);
          setSNError({
            enable: true,
            helpText: messages.sn.join(separator)
          });
        }
        if (messages.lastName && messages.lastName.length) {
          setHasLastName(false);
          setLastNameValid(false);
          setLastNameError({
            enable: true,
            helpText: messages.lastName.join(separator)
          });
        }
        if (messages.password && messages.password.length) {
          setHasPassword(false);
          setPasswordValid(false);
          setPasswordError({
            enable: true,
            helpText: messages.password.join(separator)
          });
        }

        if (!messages.sn || !messages.sn.length) {
          setHasMeterSerialNumber(false);
          setMeterSerialNumberValid(true);
          setSNError({ enable: false, helpText: '' });
        }
        if (!messages.lastName || !messages.lastName.length) {
          setHasLastName(false);
          setLastNameValid(true);
          setLastNameError({ enable: false, helpText: '' });
        }
        if (!messages.password || !messages.password.length) {
          setHasPassword(false);
          setPasswordValid(true);
          setPasswordError({ enable: false, helpText: '' });
        }
        if (!messages.confirmPassword || !messages.confirmPassword.length) {
          setHasConfirmPassword(false);
          setConfirmPasswordValid(true);
          setConfirmPasswordError({ enable: false, helpText: '' });
        }

        setLoading(false);
      });
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false);
    const fields = errorInfo && errorInfo.errorFields.reverse();
    for (const field of fields) {
      const names = field.name as String[];
      names.find(name => {
        if (name === 'email') {
          emailInput.current!.focus({
            cursor: 'start',
          });
        }
        if (name === 'phone') {
          phoneInput.current!.focus({
            cursor: 'start',
          });
        }
        if (name === 'telegram') {
          telegramInput.current!.focus({
            cursor: 'start',
          });
        }
        if (name === 'sn') {
          snInput.current!.focus({
            cursor: 'start',
          });
        }
        if (name === 'lastName') {
          lastNameInput.current!.focus({
            cursor: 'start',
          });
        }
        if (name === 'password') {
          passwordInput.current!.focus({
            cursor: 'start',
          });
        }
        if (name === 'confirmPassword') {
          confirmPasswordInput.current!.focus({
            cursor: 'start',
          });
        }
      });

    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        name='registration-form'
        layout={formLayout}
        form={form}
        initialValues={initialValues}
        onChange={onFormChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item label='Оберіть спосіб реєстрації'>
          <Radio.Group id='registrationMethod' name='registrationMethod' value={registrationMethod} defaultValue={registrationMethod} onChange={handleRegistrationTypeChange}>
            <Space direction={isMobile ? 'vertical' : 'horizontal'}>
              <Radio.Button value='email'>Email</Radio.Button>
              <Radio.Button value='phone'>Телефонний номер</Radio.Button>
              {cookies.registration_source === 'telegram' && cookies.registration_source_id && (
                <Radio.Button value='telegram'>Telegram</Radio.Button>
              )}
            </Space>
          </Radio.Group>
        </Form.Item>
        {registrationMethod === RegistrationMethodType.email && (
          <Form.Item name='email' label='Email' htmlFor='email' rules={[
            { required: registrationMethod === RegistrationMethodType.email, message: 'Будь ласка, введіть електронну пошту' },
            { pattern: emailRegex, message: 'Введена пошта не відповідає формату e-mail@domain' },
            () => ({
              validator() {
                if (emailError.enable) {
                  return Promise.reject(emailError.helpText);
                }
                return Promise.resolve();
              }
            })
          ]}
            hasFeedback validateStatus={isHasEmailValid ? '' : isEmailValid ? 'success' : 'error'}>
            <Input id='email' placeholder='Email' onChange={handleEmailChange} ref={emailInput} />
          </Form.Item>
        )}

        {registrationMethod === RegistrationMethodType.phone && (
          <Form.Item
            name='phone'
            label='Номер телефону'
            htmlFor='phone'
            rules={[
              { required: registrationMethod === RegistrationMethodType.phone, message: 'Будь ласка, введіть номер телефону' },
              { pattern: phoneRegex, message: 'Введений номер містить менше 12 цифр' },
              () => ({
                validator() {
                  if (phoneError.enable) {
                    return Promise.reject(phoneError.helpText);
                  }
                  return Promise.resolve();
                }
              })
            ]}
            hasFeedback validateStatus={isHasPhoneValid ? '' : isPhoneValid ? 'success' : 'error'}>
            <MaskedInput id='phone' mask={'+380000000000'} onChange={handlePhoneChange} ref={phoneInput} />
          </Form.Item>
        )}

        {registrationMethod === RegistrationMethodType.telegram && (
          <Form.Item
            name='telegram'
            label='Telegram ID'
            htmlFor='telegram'
            rules={[
              { required: registrationMethod === RegistrationMethodType.telegram, message: 'Будь ласка, введіть ідентифікатор Телеграм' },
              { pattern: telegramRegex, message: 'Введений ідентифікатор не відповідає формату' },
              () => ({
                validator() {
                  if (telegramError.enable) {
                    return Promise.reject(telegramError.helpText);
                  }
                  return Promise.resolve();
                }
              })
            ]}
            hasFeedback validateStatus={isHasTelegramValid ? '' : isTelegramValid ? 'success' : 'error'}>
            <Input id='telegram' placeholder='Ідентифікатор' disabled={true} onChange={handleTelegramChange} ref={telegramInput} />
          </Form.Item>
        )}

        <Form.Item
          name='sn'
          label='Серійний номер лічильника'
          tooltip='Cерійний номер знаходиться на циферблаті лічильника.'
          rules={[
            { required: true, message: 'Будь ласка, введіть серійний номер лічильника' },
            { pattern: snRegex, message: 'Введений серійний номер не відповідає формату' },
            () => ({
              validator() {
                if (snError.enable) {
                  return Promise.reject(snError.helpText);
                }
                return Promise.resolve();
              }
            })
          ]}
          hasFeedback validateStatus={isHasMeterSerialNumberValid ? '' : isMeterSerialNumberValid ? 'success' : 'error'}>
          <Input placeholder='22XXXXX | 007XXXXX | 1200XXXXXX | 8ZR100XXXXXXXX' onChange={handleMeterSerialNumberChange} ref={snInput} />
        </Form.Item>

        <Form.Item
          name='lastName'
          label='Прізвище абонента'
          rules={[
            { required: true, message: 'Будь ласка, введіть прізвище абонента' },
            { min: 2, message: 'Прізвище повинно містити щонайменше 2 символи' },
            { max: 25, message: 'Прізвище повинно містити не більше 25 символів' },
            { pattern: userLastNameRegex, message: 'Прізвище повинно містити літери українського алфавіту та мати довжину менше 25 символів' },
            () => ({
              validator() {
                if (lastNameError.enable) {
                  return Promise.reject(lastNameError.helpText);
                }
                return Promise.resolve();
              }
            })
          ]}
          hasFeedback validateStatus={isHasLastNameValid ? '' : isLastNameValid ? 'success' : 'error'}>
          <Input placeholder='Введіть прізвище абонента' onChange={handleLastNameChange} ref={lastNameInput} />
        </Form.Item>

        <Form.Item
          name='password'
          label='Пароль'
          rules={[
            { required: true, message: 'Будь ласка, введіть пароль' },
            { pattern: passwordRegex, message: 'Пароль має містити хоча б одну велику літеру і мінімум 6 символів' },
            () => ({
              validator() {
                if (passwordError.enable) {
                  return Promise.reject(passwordError.helpText);
                }
                return Promise.resolve();
              }
            })
          ]}
          hasFeedback validateStatus={isHasPasswordValid ? '' : isPasswordValid ? 'success' : 'warning'}>
          <Input.Password placeholder='Введіть пароль' onChange={handlePasswordChange} ref={passwordInput} />
        </Form.Item>

        <Form.Item
          name='confirmPassword'
          label='Повторіть пароль'
          dependencies={['password']}
          rules={[
            { required: true, message: 'Будь ласка, повторіть пароль' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Введені паролі не співпадають'));
              },
            }),
          ]}
          hasFeedback validateStatus={isHasConfirmPasswordValid ? '' : isConfirmPasswordValid ? 'success' : 'warning'}
        >
          <Input.Password placeholder='Повторіть пароль' onChange={handleConfirmPasswordChange} ref={confirmPasswordInput} />
        </Form.Item>
        <Form.Item>
          <Button className='mt-2' type='primary' shape='round' size='large' htmlType='submit' block>
            Зареєструватися
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default RegistrationForm;
