export enum UserStatus {
   enabled = 'enabled',
   disabled = 'disabled',
}

export enum RegistrationMethodType {
   telegram = 'telegram',
   viber = 'viber',
   email = 'email',
   phone = 'phone',
   manual = 'manual',
   none = 'none',
}

export default interface IUser {
   id?: any | null;
   username: string;
   password: string;
   status: UserStatus | null;
   role_id: number;
   role: any | null;
   registration_method: RegistrationMethodType;
}
